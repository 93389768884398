<template>
  <div>
    <Header v-if="!isAdd" />
    
    <section class="content-wrapper px-3">
      <div class="mb-4" v-if="isEmptyService() && isEdit">
        <skeleton grid="6" gap="10" :rows="3" height="40px" width="99px" />
      </div>
      <div v-else>
        <div class="d-flex justify-content-between align-items-start" v-if="isEdit">
          <b-tabs pills small nav-class="nav-users">
            <b-tab
              @click="handleSelectStep(step)"
              :active="current_step.key === step.key"
              v-for="(step, index) in steps"
              :key="index"
              :title="step.name"
            />
          </b-tabs>
          <b-button v-if="user.is_admin" @click="handleStartService()" class="btn-add">
            Iniciar Serviço
          </b-button>
       </div>
        <div v-else>
          <timeline-steps
            hide_status
            :steps="steps"
            :current_step="current_step"
          />
        </div>
      </div>  

      <router-view />
    </section>
    <b-modal
      hide-footer
      hide-header
      body-class="min-h-40"
      size="md"
      centered
      no-close-on-backdrop
      no-close-on-esc
      id="start-service"
    >
      <h4 class="text-center my-3">Iniciar Serviço</h4>
      <img src="@/assets/images/svg/people.svg" alt="" width="100%" />
      <strong class="d-block mt-3">
        Atenção você está iniciando o serviço desta homologação. 
        O sistema irá enviar email para os prestadores cadastrados. Essa ação não
        poderá ser revertida
      </strong>
      <div class="mt-4">
        <b-button
          :disabled="inProgress"
          block
          variant="primary"
          @click="start()"
        >
          Quero continuar
        </b-button>
        <b-button block variant="link" @click="cancel()">Voltar</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import TimelineSteps from "@/components/TimelineSteps";
import Header from "../Header.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { TimelineSteps, Header },
  computed: {
    ...mapGetters(["is_provider", "user", "service_scope"]),
  },

  data() {
    return {
      steps: [],
      service_id: "",
      homologation_id: "",
      isEdit: false,
      isAdd: false,
      inProgress: false,
      current_step: {
        key: "details",
        name: "Detalhes do serviço",
        is_finalized: false,
        order: 0,
      },
    };
  },
  methods: {
    ...mapActions(["set_empty_service_scope", "start_service_homologation", "show_toast"]),

    handleSelectStep(step) {
      this.$router.push(
        `/homologation/${this.homologation_id}/service/${this.service_id}/edit/${step.key}/`
      );
    },
    setCurrentStep(path) {
      const keys = path.split("/").filter((d) => d !== "");

      const key = keys[keys.length - 1];

      this.current_step = this.steps.find((step) => step.key === key);

      const index = this.steps.indexOf(
        this.steps.find((step) => step.key === key)
      );

      this.steps.forEach((step, i) => {
        if (i < index) {
          this.steps[i].is_finalized = true;
        }
      });

      this.$forceUpdate();
    },
    isEmptyService(){
      if (this.service_scope){
        return Object.keys(this.service_scope).length === 0
      } else {
        return true
      }
    },
    handleStartService() {
      this.$bvModal.show("start-service");
    },
    cancel() {
      this.$bvModal.hide("start-service");
    },
    start() {
      this.inProgress = true;
      this.start_service_homologation({ service_id: this.service_scope.id })
        .then(({ data, status }) => {
          if (status === 200) {
            this.$bvModal.hide("start-service");
            this.$router.push("/service/list")
          } else {
            this.show_toast({
              message: data.message,
              type: "error",
            });
          }

          this.inProgress = false;
        })
        .catch(() => {
          this.inProgress = false;
        });
    },
  },
  mounted() {
    this.isEdit = this.$route.path.split("/").includes("edit");

    const params = this.$route.params;
    this.homologation_id = params.homologation_id;
    this.service_id = params.service_id;

    this.setCurrentStep(this.$route.path);
  },
  beforeDestroy(){
    this.set_empty_service_scope()
  },
  watch: {
    $route: {
      handler(value) {
        this.isAdd = value.path.split("/").includes("add");
        if (this.steps.length === 0) {
          return;
        }
        this.setCurrentStep(value.path);
      },
      immediate: true,
      deep: true,
    },
    service_scope: {
      handler(service) {
        if (!this.user.is_admin) {
          this.steps = [
            {
              key: "details",
              name: "Detalhes do serviço",
              is_finalized: true,
              order: 0,
            },
            {
              key: "premises",
              name: "Configuração de premissas",
              is_finalized: false,
              order: 1,
            },
            {
              key: "invites",
              name: "Convite de prestadores",
              is_finalized: false,
              order: 2,
            },
          ];
          if (service.status === 1){
            this.steps.splice(0, 2)
          }
        } else {
          this.steps = [
            {
              key: "details",
              name: "Detalhes do serviço",
              is_finalized: true,
              order: 0,
            },
            {
              key: "premises-analysis",
              name: "Configuração de templates",
              is_finalized: false,
              order: 1,
            },
            {
              key: "questionaire-service",
              name: "Configuração do questionário",
              is_finalized: false,
              order: 2,
            },
            {
              key: "document-service",
              name: "Configuração de documentos",
              is_finalized: false,
              order: 3,
            },
          ];
        }
      },
      immediate: true,
    },
  },
};
</script>
