<template>
  <div class="w-100 h-100 p-3" ref="templatePDF">
    <h3 class="d-flex align-items-center justify-content-between">
      {{ homologation.name }}
    </h3>
    <span> {{ homologation.status_display }}</span>
    <div class="my-3" v-if="homologation.borrower">
      <strong class="text-muted d-block">Tomador: </strong>
      <img
        class="my-2"
        v-if="homologation.borrower.logo"
        :src="homologation.borrower.logo.file"
        :alt="homologation.borrower.logo.name"
        height="40"
      />
      <p class="mb-0 text-truncate">
        <b-icon icon="person" class="mr-2" />
        {{ homologation.borrower.social_reason }}
      </p>
      <p>
        <b-icon icon="building" class="mr-2" />
        {{ homologation.borrower.cnpj | VMask("##.###.###/####-##") }}
      </p>
    </div>
    <div class="my-3" v-if="homologation.borrower_responsible">
      <strong>Responsável Legal</strong>
      <div class="mt-2 text-muted d-flex align-items-center">
        <b-icon icon="person" />
        <span class="mx-2">{{ homologation.borrower_responsible.name }}</span>
      </div>
      <div>
        <b-icon icon="envelope" />
        <span class="mx-2"> {{ homologation.borrower_responsible.email }}</span>
      </div>
    </div>

    <div class="my-3">
      <strong>Localização</strong>
      <div class="mt-2 text-muted">
        <div class="d-flex align-items-center" v-if="homologation.state">
          <b-icon icon="map" />
          <span class="mx-2"> Estado: {{ homologation.state.name }} </span>
        </div>
        <div class="d-flex align-items-center mt-1" v-if="homologation.state">
          <b-icon icon="map" />
          <span class="mx-2 d-block">
            Cidade:
            {{ homologation.city.name }}
          </span>
        </div>
      </div>
    </div>

    <div class="my-3">
      <strong>Progresso</strong>
      <div class="mt-2 text-muted d-flex align-items-center gap-2">
        <b-progress max="100" height="12px" class="w-100">
          <b-progress-bar :value="homologation.percentage" />
        </b-progress>
        <span class="d-flex align-items-center">
          {{ homologation.percentage }}%
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    homologation: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["is_provider"]),
  },
};
</script>
