<template>
  <ul class="timeline pl-0" id="timeline">
    <li
      class="li"
      :class="getClass(step)"
      v-for="(step, index) in steps"
      :key="index"
    >
      <div class="timestamp">
        <span class="title d-block" v-if="step.icon">
          <b-icon :icon="step.icon" />
        </span>
        <span class="title">
          {{ step.name.replace("Cadastro do ", "") }}
        </span>
        <small class="text-muted" v-if="!hide_status">
          {{ getStatus(step) }}
        </small>
      </div>
      <div class="status"></div>
    </li>
  </ul>
</template>
<script>
export default {
  name: "TimelineSteps",
  props: {
    hide_status: {
      type: Boolean,
    },
    steps: {
      type: Array,
    },
    current_step: {
      type: Object,
    },
  },
  methods: {
    getClass(step) {
      if (step.is_finalized) {
        return "complete";
      }
      if (this.current_step?.order === step.order) {
        return "current";
      }
    },
    getStatus(step) {
      if (step.is_finalized) {
        return "Finalizado";
      }
      if (this.current_step.order === step.order) {
        return "Em andamento";
      }
      return "Pendente";
    },
  },
};
</script>
<style lang="scss">
.timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.li {
  transition: all 200ms ease-in;
  width: 100%;
  padding: 12px 0 0;
}
.timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
  span {
    text-align: center;
  }
}
.status {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  border-top: 2px solid $bg-2;
  position: relative;
  transition: all 200ms ease-in;
  h4 {
    font-weight: 600;
  }
  &:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: $bg-2;
    border-radius: 25px;
    border: 1px solid $bg;
    position: absolute;
    top: -15px;
    right: 0;
    transition: all 200ms ease-in;
  }
}
.li {
  &.complete {
    .status {
      border-top: 2px solid $success;
      &:before {
        background-color: $success;
        border: none;
        transition: all 200ms ease-in;
      }
      h4 {
        color: $success;
      }
    }
  }
  &.current {
    .status {
      border-top: 2px solid $info;
      h4 {
        color: $success;
      }
    }
  }
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline {
    list-style-type: none;
    width: 100%;
    display: none;
  }
  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px 0;
  }
  .timestamp {
    width: 100%;
    padding: 0px 10px;
  }
  .status {
    padding: 0;
    width: calc(100% - 4px);
    &:before {
      left: -8%;
      top: -12px;
      transition: all 200ms ease-in;
    }
  }
}
</style>